<template>
  <div class="container mypage withTop">
    <section class="contents Rgt detail daily">
      <div class="buttonWrap">
        <button class="point" @click="handleMove()">수정</button>
        <button class="line" @click="handleDelete()">삭제</button>
      </div>
      <article class="box">
        <p>재배품목</p>
        <p>{{ crop }}</p>
        <p>영농작업</p>
        <p class="category">
          <span v-if="category.includes('category1')">농약</span>
          <span v-if="category.includes('category2')">관수</span>
          <span v-if="category.includes('category3')">해충기록</span>
          <span v-if="category.includes('category4')">장비정비</span>
          <span v-if="category.includes('category5')">수확</span>
          <span v-if="category.includes('category6')">출고</span>
          <span v-if="category.includes('category7')">기타</span>
        </p>
        <p>작업일</p>
        <p>{{ moment(date).format("YYYY년 MM월 DD일") }}</p>

        <div v-if="category.includes('category1')">
          <hr color="#eeeeee" />
          <p class="bold">농약</p>
          <p>처리날짜</p>
          <p>{{ moment(pesticide_1).format("YYYY년 MM월 DD일") }}</p>
          <p>농약제품</p>
          <p>{{ pesticide_2 }}</p>
          <p>대상병해충</p>
          <p>{{ pesticide_3 }}</p>
          <p>농약제품 구입량</p>
          <p>{{ pesticide_4 }}</p>
          <p>농약제품 사용량</p>
          <p>{{ pesticide_5 }}</p>
          <p>농약처리방법</p>
          <el-checkbox-group v-model="pesticide_6">
            <el-checkbox disabled label="관수"></el-checkbox>
            <el-checkbox disabled label="기계"></el-checkbox>
          </el-checkbox-group>
          <p>농약처리장비명</p>
          <el-checkbox-group v-model="pesticide_7">
            <el-checkbox disabled label="SS기"></el-checkbox>
            <el-checkbox disabled label="동력분무기"></el-checkbox>
          </el-checkbox-group>
        </div>

        <div v-if="category.includes('category2')">
          <hr color="#eeeeee" />
          <p class="bold">관수</p>
          <p>수원</p>
          <p>{{ irrn_1 }}</p>
          <p>수원번호</p>
          <p>{{ irrn_2 }}</p>
          <p>관수시간</p>
          <p>{{ irrn_3 }}</p>
          <p>관수량</p>
          <p>{{ irrn_4 }}</p>
        </div>

        <div v-if="category.includes('category3')">
          <hr color="#eeeeee" />
          <p class="bold">해충기록</p>
          <p>관찰날짜</p>
          <p>{{ moment(dbyhs_1).format("YYYY년 MM월 DD일") }}</p>
          <p>조사방법</p>
          <p>{{ dbyhs_2 }}</p>
        </div>

        <div v-if="category.includes('category4')">
          <hr color="#eeeeee" />
          <p class="bold">장비정비</p>
          <p>장비종류</p>
          <p>{{ equipment_1 }}</p>
          <p>관리사유</p>
          <p>{{ equipment_2 }}</p>
        </div>

        <div v-if="category.includes('category5')">
          <hr color="#eeeeee" />
          <p class="bold">수확</p>
          <p>생산량</p>
          <p>{{ hvst_1 }}</p>
          <p>생산날짜</p>
          <p>{{ moment(hvst_2).format("YYYY년 MM월 DD일") }}</p>
        </div>

        <div v-if="category.includes('category6')">
          <hr color="#eeeeee" />
          <p class="bold">출고</p>
          <p>출하량</p>
          <p>{{ release_1 }}</p>
          <p>출하날짜</p>
          <p>{{ moment(release_2).format("YYYY년 MM월 DD일") }}</p>
        </div>

        <div v-if="category.includes('category7')">
          <hr color="#eeeeee" />
          <p class="bold">기타</p>
          <p>메모</p>
          <pre
            >{{ etc_1 }}
          </pre>
          <p>사진</p>
          <div class="imgBox">
            <ul v-if="images && images.length > 0">
              <li v-for="(image, i) in images" :key="i">
                <img :src="image" alt="이미지" id="img" />
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
import { fetchDiaryDetails, deleteDiary } from "@/api/diary";
import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
      diaryId: "",
      category: [],
      farmer: "",
      crop: "",
      pesticide_1: "", //농약
      pesticide_2: "",
      pesticide_3: "",
      pesticide_4: "",
      pesticide_5: "",
      pesticide_6: [],
      pesticide_7: [],
      irrn_1: "", //관수
      irrn_2: "",
      irrn_3: "",
      irrn_4: "",
      dbyhs_1: "", //해충
      dbyhs_2: "",
      equipment_1: "", // 장비
      equipment_2: "",
      hvst_1: "", //수확
      hvst_2: "",
      release_1: "", // 출고
      release_2: "",
      etc_1: "", //기타
      date: "",
      images: [],
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "생육데이터관리");
  },
  mounted() {
    if (this.$route.query.id) {
      this.diaryId = this.$route.query.id;

      this.getDiaryDetail();
    }
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getDiaryDetail() {
      fetchDiaryDetails(this.diaryId).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.farmer = res.data.data.userId;
          this.crop = res.data.data.crop;
          this.pesticide_1 = res.data.data.pesticide_1; //농약
          this.pesticide_2 = res.data.data.pesticide_2;
          this.pesticide_3 = res.data.data.pesticide_3;
          this.pesticide_4 = res.data.data.pesticide_4;
          this.pesticide_5 = res.data.data.pesticide_5;
          this.pesticide_6 = res.data.data.pesticide_6;
          this.pesticide_7 = res.data.data.pesticide_7;
          this.irrn_1 = res.data.data.irrn_1; //관수
          this.irrn_2 = res.data.data.irrn_2;
          this.irrn_3 = res.data.data.irrn_3;
          this.irrn_4 = res.data.data.irrn_4;
          this.dbyhs_1 = res.data.data.dbyhs_1; //해충
          this.dbyhs_2 = res.data.data.dbyhs_2;
          this.equipment_1 = res.data.data.equipment_1; // 장비
          this.equipment_2 = res.data.data.equipment_2;
          this.hvst_1 = res.data.data.hvst_1; //수확
          this.hvst_2 = res.data.data.hvst_2;
          this.release_1 = res.data.data.release_1; // 출고
          this.release_2 = res.data.data.release_2;
          this.etc_1 = res.data.data.etc_1; //기타
          this.images = res.data.data.images;
          this.date = res.data.data.date;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    handleMove() {
      this.$router.push({
        name: "dailyRegister",
        query: { id: this.diaryId },
      });
    },

    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        center: true,
      })
        .then(() => {
          deleteDiary(this.diaryId).then((res) => {
            if (res.data.status == 200) {
              this.$router.replace("/daily");
            } else {
              this.openAlert(res.data.message);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
